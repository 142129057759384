import React, { useEffect, useState } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Merchant from './Merchant';
import Amount from './Amount';
import Status from './Status';
import { useDispatch, useSelector } from 'react-redux';
import Transaction from './Transaction';
import TransactionHistory from './TransactionHistory';
import merchantService from 'src/services/merchantService';
import userService from 'src/services/userService';
import RechargeAndPayment from './RechargeAndPayment';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const Dashboard = () => {
  const classes = useStyles();

  const user = useSelector((state) => state.user.profile);
  const transactions = useSelector((state) => state.merchant.transactions);
  const dispatch = useDispatch();

  useEffect(() => {
    async function getTransaction() {
      try {
        merchantService.getTransactions(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getTransaction();
  }, []);

  useEffect(() => {
    async function getUser() {
      try {
        await userService.profile(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getUser();
  }, []);

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Merchant data={user.name} />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Amount data={user.amount} />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Status data={user.status} />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Transaction data={transactions.length} />
          </Grid>
          <Grid item lg={8} md={12} xl={9} xs={12}>
            <TransactionHistory />
          </Grid>
          <Grid item lg={4} md={6} xl={3} xs={12}>
            <RechargeAndPayment />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
