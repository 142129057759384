import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';

import TransactionTable from './TransactionTable';
import { Helmet } from 'react-helmet';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const TransactionView = () => {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>Transactions</title>
      </Helmet>
      <Box className={classes.root}>
        <Container maxWidth={false}>
          <Box mt={3}>
            <TransactionTable />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default TransactionView;
