import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
  LinearProgress,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import Page from 'src/components/Page';
import userService from 'src/services/userService';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import api from 'src/services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const LoginView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  let navigate = useNavigate();

  const pathname = location.state?.pathname;

  const [errorMessage, setErrorMessage] = useState('');

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    showPassword ? setShowPassword(false) : setShowPassword(true);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [emailOrPhone, setEmailOrPhone] = useState('phone');
  console.log(emailOrPhone);
  return (
    <Page className={classes.root} title="Merchant Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              // phone: '',
              password: '',
              emailPhone: '',
            }}
            validationSchema={Yup.object().shape({
              // phone: Yup.string()
              //   .matches(
              //     /^[9][0-9]{9}$/,
              //     'Invalid phone number. Phone number must be in this format: 9876543210',
              //   )
              //   .required('Phone number is required'),
              password: Yup.string()
                .max(255)
                .required('Password is required')
                .min(8, 'Password is minimum 8 characters.'),
              emailPhone: Yup.string('Enter your Email/Phone Number')
                .required(
                  'Email/Phone Number is required. Phone Number must be in this format: 9876543210',
                )
                .test('test-name', 'Enter Valid Phone/Email', function (value) {
                  const emailRegex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                  const phoneRegex = /^[9][0-9]{9}$/; //
                  let isValidEmail = emailRegex.test(value);
                  let isValidPhone = phoneRegex.test(value);
                  if (!isValidEmail && !isValidPhone) {
                    return false;
                  }
                  if (isValidEmail) {
                    setEmailOrPhone('email');
                  }
                  if (isValidPhone) {
                    setEmailOrPhone('phone');
                  }
                  return true;
                }),
            })}
            onSubmit={async (values) => {
              try {
                setErrorMessage('');
                const { emailPhone, password } = values;

                const data = { password, role: 'merchant' };
                if (emailOrPhone === 'phone') {
                  data.phone = '+977' + emailPhone;
                } else {
                  data.email = emailPhone;
                }
                console.log('data', data);

                let res = await userService.merchantLogin(data, dispatch);

                // userService.merchantLogin(
                //   { email, password, panel: 'merchant' },
                //   dispatch,
                // );
                // console.log(pathname);

                if (res && res.ok) {
                  if (pathname) {
                    window.location.replace(pathname);
                    return;
                  }
                  window.location.replace('/');
                }
              } catch (error) {
                console.error(('error', error));

                setErrorMessage(
                  error.message || 'Login Error. Please try again.',
                );
              }
            }}
          >
            {({
              isSubmitting,
              // values,
            }) => (
              <Form>
                <Box mb={3}>
                  <Typography align="center" color="textPrimary" variant="h2">
                    Merchant Login
                  </Typography>
                </Box>
                <Box mt={3} mb={1}>
                  <Typography
                    align="center"
                    color="textSecondary"
                    variant="body1"
                  >
                    Login with merchant credentials only
                  </Typography>
                  {errorMessage && (
                    <Alert severity="error" onClose={() => setErrorMessage('')}>
                      <AlertTitle>Error</AlertTitle>
                      {errorMessage}
                    </Alert>
                  )}
                </Box>
                {/* <Field
                  component={TextField}
                  label="Phone Number"
                  margin="normal"
                  name="phone"
                  type="phone"
                  fullWidth
                  variant="outlined"
                  autoComplete="username"
                /> */}
                <Field
                  component={TextField}
                  label="Email or Phone Number"
                  margin="normal"
                  name="emailPhone"
                  type="text"
                  fullWidth
                  variant="outlined"
                />
                {/* <Field
                  component={TextField}
                  fullWidth
                  label="Password"
                  margin="normal"
                  name="password"
                  type="password"
                  variant="outlined"
                  autoComplete="current-password"
                /> */}

                <Field
                  component={TextField}
                  label="Password"
                  size="medium"
                  name="password"
                  margin="normal"
                  type={showPassword ? 'text' : 'password'}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {isSubmitting && <LinearProgress />}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Login
                  </Button>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <span>Dont have an account?</span>
                  <Link to="/register">
                    {' '}
                    <span
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    >
                      Register Now
                    </span>
                  </Link>
                </Box>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
