import {
  ADMIN_GET_PRIVACY_POLICY,
  ADMIN_ADD_PRIVACY_POLICY,
} from '../actions/privacyPolicyActions';

const initialState = {
  privacyPolicy: [],
};

export default function privacyPolicyReudcers(state = initialState, action) {
  switch (action.type) {
    case ADMIN_GET_PRIVACY_POLICY: {
      return {
        ...state,
        privacyPolicy: [...action.payload],
      };
    }
    case ADMIN_ADD_PRIVACY_POLICY: {
      const privacyPolicy = [];
      privacyPolicy.push(action.payload);
      return {
        ...state,
        privacyPolicy: privacyPolicy,
      };
    }
    default:
      return state;
  }
}
