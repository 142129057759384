import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import Page from 'src/components/Page';
import api from 'src/services/api';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const VerifyAccount = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (values, action) => {
    try {
      const res = await api(`/auth/confirmation/phone/${values?.code}`);

      navigate('/login');
    } catch (error) {
      console.log(error.message);
      setErrorMessage(error.message);

      // action.setSumitting();
    }
  };

  return (
    <Page className={classes.root} title="Register">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              // email: '',
              code: '',
            }}
            validationSchema={Yup.object().shape({
              // email: Yup.string().email('Must be a valid email').max(255),

              code: Yup.string()
                .required('Code is required')
                .matches(/^[0-9][0-9]{5}}$/, '        "Invalid code number'),
            })}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              action,
            }) => (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();

                  handleSubmit(values, action);
                }}
              >
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    Verify Your account
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Use your code to verify your account
                  </Typography>
                  {errorMessage && (
                    <Alert severity="error" onClose={() => setErrorMessage('')}>
                      <AlertTitle>Error</AlertTitle>
                      {errorMessage}
                    </Alert>
                  )}
                </Box>
                <TextField
                  error={Boolean(touched.code && errors.code)}
                  fullWidth
                  helperText={touched.code && errors.code}
                  label="Code"
                  margin="normal"
                  name="code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.code}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Verify now
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                  Already Verified?{' '}
                  <Link component={RouterLink} to="/login" variant="h6">
                    Sign in
                  </Link>
                </Typography>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default VerifyAccount;
