import {
  Avatar,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  LinearProgress,
  makeStyles,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Page from 'src/components/Page';
import { consoleError, getImageUrl } from 'src/utils/helper';
import * as yup from 'yup';
import { TextField, Switch } from 'formik-material-ui';
import { useLocation } from 'react-router';
import { RiImageAddFill } from 'react-icons/ri';
import promotionServices from 'src/services/problemService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // height: 'auto',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  imageUploadBox: {
    width: '100%',
    backgroundColor: '#ccc',
    borderRadius: 5,
    color: '#fff',
    minHeight: 80,
    maxHeight: 160,
    objectFit: 'fill',
    cursor: 'pointer',
  },
  errorMsg: {
    color: 'error.main',
    ml: 3,
  },
}));

function AddProblem({ promotion = null }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  const [update, setUpdate] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  useEffect(() => {
    if (promotion) {
      setUpdate(true);
    }
  }, [promotion]);

  const addPromotionSchema = yup.object().shape({
    type: yup.string().required('Type is required'),

    category: yup.string().required('Category is required'),

    info: yup.string().required('Info is required'),
  });

  return (
    <Page className={classes.root} title={'Add Promotion'}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            enableReinitialize={true}
            initialValues={
              update
                ? {
                    id: promotion._id,
                    type: promotion.type,
                    category: promotion.category,
                    info: promotion.info,
                  }
                : {
                    type: '',
                    category: '',
                    info: '',
                  }
            }
            validationSchema={addPromotionSchema}
            onSubmit={async (values, { resetForm }) => {
              setErrMsg('');
              setSuccessMsg('');
              const { id, type, category, info } = values;
              if (update) {
                try {
                  let res = await promotionServices.updateProblem(
                    id,
                    { ...values },
                    dispatch,
                  );

                  if (res && res.ok) {
                    setSuccessMsg(
                      res.message || 'problem updated successfully.',
                    );
                    // window.location.reload();
                  }
                  //
                } catch (error) {
                  consoleError(error);
                  setErrMsg(
                    error.message ||
                      'Error updating Problem. Please try again.',
                  );
                }
              } else {
                try {
                  let res = await promotionServices.addProblem(
                    { ...values },
                    dispatch,
                  );
                  if (res && res.ok) {
                    setSuccessMsg(res.message || 'Problem added successfully.');
                    resetForm({});
                    // window.location.reload();
                  }
                  //
                } catch (error) {
                  consoleError(error);
                  setErrMsg(
                    error.message || 'Error adding Problem. Please try again.',
                  );
                }
              }
            }}
          >
            {({ isSubmitting, values, errors, setFieldValue }) => (
              <Form>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Type"
                      size="medium"
                      name="type"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Info"
                      size="medium"
                      name="info"
                      multiline
                      rows={3}
                      maxRows={5}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Category"
                      size="medium"
                      name="category"
                      multiline
                      rows={3}
                      maxRows={5}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Box mt={1} mb={1}>
                  {errMsg && (
                    <Alert severity="error" onClose={() => setErrMsg('')}>
                      <AlertTitle>Error</AlertTitle>
                      {errMsg}
                    </Alert>
                  )}
                  {successMsg && (
                    <Alert severity="success" onClose={() => setSuccessMsg('')}>
                      <AlertTitle>Success</AlertTitle>
                      {successMsg}
                    </Alert>
                  )}
                </Box>
                {isSubmitting && <LinearProgress />}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="medium"
                    type="submit"
                    variant="contained"
                  >
                    Add Problem
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
}

export default AddProblem;
