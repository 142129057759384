import { ADMIN_GET_OFFERS } from '../actions/offerActions';

const initialState = {
  offers: [],
};

export default function offerReducer(state = initialState, action) {
  switch (action.type) {
    case ADMIN_GET_OFFERS: {
      return {
        ...state,
        offers: [...action.payload],
      };
    }

    default:
      return state;
  }
}
