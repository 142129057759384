import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
  colors,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import moment from 'moment';
import merchantService from 'src/services/merchantService';

const useStyles = makeStyles(() => ({
  root: {},
}));

const TransactionHistory = ({ className, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const today = new Date();

  const [fiveDaysTransaction, setFiveDaysTransaction] = useState([]);

  let datasetData = Object.values(fiveDaysTransaction)
    .map((item) => item.length)
    .reverse();

  let labels = Object.values(fiveDaysTransaction)
    .map((item, index) =>
      moment(today).subtract(index, 'days').format('DD MMM'),
    )
    .reverse();

  // console.log(datasetData, [
  //   fiveDaysTransaction?.day5?.length || 0,
  //   fiveDaysTransaction?.day4?.length || 0,
  //   fiveDaysTransaction?.day3?.length || 0,
  //   fiveDaysTransaction?.day2?.length || 0,
  //   fiveDaysTransaction?.day1?.length || 0,
  // ]);
  const data = {
    datasets: [
      {
        backgroundColor: colors.indigo[500],
        data: datasetData,
        label: 'Transaction Count',
      },
    ],
    labels: labels,
    //  [
    //   moment(today).subtract(4, 'days').format('DD MMM YYYY'),
    //   moment(today).subtract(3, 'days').format('DD MMM YYYY'),
    //   moment(today).subtract(2, 'days').format('DD MMM YYYY'),
    //   moment(today).subtract(1, 'days').format('DD MMM YYYY'),
    //   moment(today).format('DD MMM YYYY'),
    // ],
  };

  useEffect(() => {
    async function getTransactions() {
      try {
        let res = await merchantService.getDashboardTransactionHistory();
        setFiveDaysTransaction(res.data);
      } catch (error) {
        console.log(error);
      }
    }
    getTransactions();
  }, []);

  // console.log(fiveDaysTransaction);

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
            precision: 0,
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider,
          },
        },
      ],
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary,
    },
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        action={() => (
          <Button endIcon={<ArrowDropDownIcon />} size="small" variant="text">
            Last 7 days
          </Button>
        )}
        title="30 Days Transaction History"
      />
      <Divider />
      <CardContent>
        <Box height={400} position="relative">
          <Bar data={data} options={options} />
        </Box>
      </CardContent>
      <Divider />
      {/* <Box display="flex" justifyContent="flex-end" p={2}>
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
        >
          Overview
        </Button>
      </Box> */}
    </Card>
  );
};

TransactionHistory.propTypes = {
  className: PropTypes.string,
};

export default TransactionHistory;
