import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Box, Card, makeStyles } from '@material-ui/core';
import MaterialTable from 'material-table';
import { tableIcons } from 'src/components/common/TableIcons';
import adminService from 'src/services/adminService';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import merchantService from 'src/services/merchantService';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 10px',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const TransactionTable = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // const transactionss = useSelector((state) => state.admin.transactions);
  const transactions = useSelector((state) => state.merchant.transactions);

  // console.log('Merchant', merchantTransactions);

  const tansactionData = transactions?.map((data) => {
    return {
      ...data,
      clientName:
        data.client || data.client?.status === 'inactive'
          ? data.clientName
          : data.clientName + '*',
    };
  });
  console.log('tansactionData', tansactionData);

  // useEffect(() => {
  //   async function getTransaction() {
  //     try {
  //       adminService.getTransaction(dispatch);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  //   getTransaction();
  // }, []);

  useEffect(() => {
    async function getTransaction() {
      try {
        merchantService.getTransactions(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getTransaction();
  }, []);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Box>
        <MaterialTable
          icons={tableIcons}
          columns={[
            {
              title: 'Client',
              field: 'clientName',
            },
            {
              title: 'Transaction Amount',
              field: 'amount',
            },
            {
              title: 'Recharge Request',
              field: 'rechargeRequest',
            },
            {
              title: 'Date',
              field: 'created At',
              render: (rowData) =>
                moment(rowData.createdAt).format('DD/MM/YYYY h:mm A'),
            },
          ]}
          data={tansactionData}
          title="Transactions"
          // onRowClick={(event, rowData) => navigate(`./${rowData._id}`)}
          // actions={[
          //   {
          //     icon: DeleteIcon,
          //     tooltip: 'Delete contactus',
          //     onClick: (event, rowData) => {
          //       event.stopPropagation();
          //       setDeleteContact(rowData);
          //       openDeleteModal();
          //     },
          //   },
          // ]}
          options={{
            rowStyle: (rowData) => {
              console.log('zzz', rowData);
              return {
                fontSize: '1rem',
                fontWeight: 'lighter',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serifs',
                backgroundColor: rowData.client === null ? '#fff1f0' : '#fff',
              };
            },
            actionsColumnIndex: -1,
          }}
        />
      </Box>
    </Card>
  );
};
TransactionTable.propTypes = {
  className: PropTypes.string,
};
export default TransactionTable;
