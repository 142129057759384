import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Box, Card, Typography, makeStyles } from '@material-ui/core';
import { consoleError, getImageUrl } from 'src/utils/helper';
import MaterialTable from 'material-table';
import { tableIcons } from 'src/components/common/TableIcons';
import { useDispatch, useSelector } from 'react-redux';
import offerServices from 'src/services/offerService';
import { Lightbox } from 'react-modal-image';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const OfferTable = ({ className, slug, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const offers = useSelector((state) => state.offer.offers);

  useEffect(() => {
    async function getOffers() {
      try {
        offerServices.getOffers(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getOffers();
  }, [slug]);

  const [currentImage, setCurrentImage] = useState(null);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  function closeImageModel() {
    setImageModalOpen(false);
  }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Box>
        <MaterialTable
          icons={tableIcons}
          columns={[
            {
              title: 'Type',
              field: 'type',
              cellStyle: {
                minWidth: '50px',
                maxWidth: '50px',
              },

              // eslint-disable-next-line react/display-name
              render: (rowData) => (
                <Box alignItems="center" display="flex">
                  <Typography color="textPrimary" variant="body1">
                    {rowData?.type}
                  </Typography>
                </Box>
              ),
            },
            {
              title: 'Link',
              field: 'link',
              filtering: false,

              // eslint-disable-next-line react/display-name
              render: (rowData) => (
                <Box alignItems="center" display="flex">
                  <a href={rowData?.link} target="_blank" rel="noreferrer">
                    <Typography color="textPrimary" variant="body1">
                      {rowData?.link.length > 50
                        ? rowData?.link.slice(0, 50) + '...'
                        : rowData?.link}
                    </Typography>
                  </a>
                </Box>
              ),
            },
            // {
            //   title: 'Role',
            //   field: 'role',
            // },
            {
              title: 'Status',
              field: 'status',
              width: 40,
              lookup: { active: 'Active', inactive: 'Inactive' },
            },
            {
              title: 'Banner Image',
              field: 'bannerImage',
              filtering: false,
              // eslint-disable-next-line react/display-name
              render: (rowData) => (
                <Box
                  alignItems="center"
                  display="flex"
                  maxHeight={60}
                  maxWidth={200}
                >
                  <img
                    src={getImageUrl(rowData?.bannerImage)}
                    alt="banner"
                    height="70"
                    role="presentation"
                    onClick={() => {
                      setCurrentImage(getImageUrl(rowData?.bannerImage));
                      setImageModalOpen(true);
                    }}
                  />
                </Box>
              ),
            },
          ]}
          data={offers}
          // onRowClick={(event, rowData) => navigate(`./${rowData._id}`)}
          title="Offers"
          options={{
            rowStyle: {
              fontSize: '1rem',
              fontWeight: 'lighter',
              fontFamily: 'Roboto, Helvetica, Arial, sans-serifs',
            },
            actionsColumnIndex: -1,
            filtering: true,
          }}
        />
      </Box>

      {imageModalOpen && (
        <Lightbox
          medium={currentImage}
          large={currentImage}
          alt="KYC IMAGE"
          onClose={closeImageModel}
          hideDownload={true}
          showRotate={true}
          imageBackgroundColor="lightgrey"
        />
      )}
    </Card>
  );
};

OfferTable.propTypes = {
  className: PropTypes.string,
  slug: PropTypes.string,
};

export default OfferTable;
