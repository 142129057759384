import {
  ADMIN_GET_TERMS_AND_CONDITIONS,
  ADMIN_ADD_TERMS_AND_CONDITIONS,
} from '../actions/termsAndConditionsActions';

const initialState = {
  termsAndConditions: [],
};

export default function termsAndConditionsReudcers(
  state = initialState,
  action,
) {
  switch (action.type) {
    case ADMIN_GET_TERMS_AND_CONDITIONS: {
      return {
        ...state,
        termsAndConditions: [...action.payload],
      };
    }
    case ADMIN_ADD_TERMS_AND_CONDITIONS: {
      const termsAndConditions = [];
      termsAndConditions.push(action.payload);
      return {
        ...state,
        termsAndConditions: termsAndConditions,
      };
    }
    default:
      return state;
  }
}
