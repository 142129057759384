import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Avatar,
  Box,
  Card,
  Typography,
  makeStyles,
  Dialog,
  Button,
  Snackbar,
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import { consoleError, getImageUrl } from 'src/utils/helper';
import MaterialTable from 'material-table';
import { tableIcons } from 'src/components/common/TableIcons';
import {
  Bell as BellIcon,
  Edit as EditIcon,
  Trash2 as DeleteIcon,
} from 'react-feather';
import adminService from 'src/services/adminService';
import { useDispatch, useSelector } from 'react-redux';
import {
  DialogActions,
  DialogTitle,
  DialogContent,
} from 'src/components/CustomDialog';
import { Alert } from '@material-ui/lab';
import AddPromotion from './AddProblem';

import promotionServices from 'src/services/problemService';
import { Lightbox } from 'react-modal-image';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const ProblemTable = ({ className, slug, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const promotions = useSelector((state) => state.problem.problems);
  const [currentPromotion, setCurrentPromotion] = useState({});

  useEffect(() => {
    async function getPromotions() {
      try {
        promotionServices.getProblems(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getPromotions();
  }, [slug]);

  const [updateModal, setUpdateModal] = useState(false);
  const openUpdateModal = () => {
    setUpdateModal(true);
  };
  const closeUpdateModal = () => {
    setUpdateModal(false);
    setCurrentPromotion({});
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const openDeleteModal = () => {
    setDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setDeleteModal(false);
    setCurrentPromotion({});
  };
  const handleDeletePromotion = async () => {
    try {
      console.log(currentPromotion._id);
      await promotionServices.deleteProblem(currentPromotion._id, dispatch);
      closeDeleteModal();
      setActionSuccess(true);
    } catch (error) {
      consoleError(error);
      closeDeleteModal();
      setActionError(true);
    }
  };
  const [notificationModal, setNotificationModal] = useState(false);
  const openNotificationModal = () => {
    setNotificationModal(true);
  };
  const closeNotificationModal = () => {
    setNotificationModal(false);
    setCurrentPromotion({});
  };
  const handleNotificationTrigger = async () => {
    try {
      await promotionServices.triggerNotification(currentPromotion._id);
      closeNotificationModal();
      setActionSuccess(true);
    } catch (error) {
      console.log(error);
      closeNotificationModal();
      setActionError(true);
    }
  };

  const [actionSuccess, setActionSuccess] = useState(false);
  const [actionError, setActionError] = useState(false);
  const [currentAction, setCurrentAction] = useState('');

  const [currentImage, setCurrentImage] = useState(null);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  function closeImageModel() {
    setImageModalOpen(false);
  }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Box>
        <MaterialTable
          icons={tableIcons}
          columns={[
            {
              title: 'Status',
              field: 'status',
              filtering: true,
              cellStyle: {
                minWidth: '50px',
                maxWidth: '50px',
              },
              lookup: {
                inWork: 'In Work',
                inQueue: 'In Queue',
                resolved: 'Resolved',
                closed: 'Closed',
              },

              // eslint-disable-next-line react/display-name
              render: (rowData) => (
                <Box alignItems="center" display="flex">
                  <Typography color="textPrimary" variant="body1">
                    {rowData?.status}
                  </Typography>
                </Box>
              ),
            },
            {
              title: 'Category',
              filtering: false,
              // eslint-disable-next-line react/display-name
              field: 'category',
            },
            {
              title: 'User',
              // eslint-disable-next-line react/display-name
              render: (rowData) => (
                <p>{rowData.user ? rowData.user.name : ''}</p>
              ),
            },
            // {
            //   title: 'Role',
            //   field: 'role',
            // },
            {
              title: 'Date',
              field: 'createdAt',
              type: 'date',
              filtering: true,
              dateSetting: { locale: 'en-GB' },

              // eslint-disable-next-line react/display-name
              render: (rowData) => (
                <Box alignItems="center" display="flex">
                  <p>{moment(rowData?.createdAt).format('MMM Do YY')}</p>
                </Box>
              ),
            },

            {
              title: 'Info',
              field: 'info',
              headerStyle: {
                width: '80px  ',
              },
              cellStyle: {
                width: '80px  ',
              },
              filtering: false,
              // eslint-disable-next-line react/display-name
              render: (rowData) => (
                <Box alignItems="center" display="flex">
                  <p>
                    {rowData?.info.length > 35
                      ? rowData?.info.slice(0, 35) + '...'
                      : rowData?.info}
                  </p>
                </Box>
              ),
            },
          ]}
          data={promotions}
          // onRowClick={(event, rowData) => navigate(`./${rowData._id}`)}
          title="Problems"
          actions={[
            {
              icon: EditIcon,
              tooltip: 'Edit Useer',
              onClick: (event, rowData) => {
                event.stopPropagation();
                setCurrentPromotion(rowData);
                setCurrentAction('update');
                openUpdateModal();
              },
            },
            {
              icon: DeleteIcon,
              tooltip: 'Delete User',
              onClick: (event, rowData) => {
                event.stopPropagation();
                setCurrentPromotion(rowData);
                setCurrentAction('deletion');
                openDeleteModal();
              },
            },
          ]}
          options={{
            tableLayout: 'auto',
            rowStyle: {
              fontSize: '1rem',
              fontWeight: 'lighter',
              fontFamily: 'Roboto, Helvetica, Arial, sans-serifs',
              overflowWrap: 'break-word',
              maxHeight: '50px',
            },
            actionsColumnIndex: -1,
            filtering: true,
          }}
        />
      </Box>
      <Dialog open={updateModal} onClose={closeUpdateModal}>
        <DialogTitle onClose={closeUpdateModal}>Update Problem</DialogTitle>
        <DialogContent>
          <AddPromotion promotion={currentPromotion} />
        </DialogContent>
      </Dialog>
      <Dialog open={deleteModal} onClose={closeDeleteModal}>
        <DialogTitle onClose={closeDeleteModal}>Delete Problem</DialogTitle>
        <DialogContent>
          <Box
            height="50px"
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Typography variant="h6">
              Do you really want to delete the problem?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeletePromotion} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* <Dialog open={notificationModal} onClose={closeNotificationModal}>
        <DialogTitle onClose={closeNotificationModal}>
          Trigger Notification
        </DialogTitle>
        <DialogContent>
          <Box
            height="50px"
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Typography variant="h6">
              Do you want to trigger a promotion notification?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeNotificationModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleNotificationTrigger} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog> */}

      <Snackbar
        open={actionSuccess}
        autoHideDuration={3000}
        onClose={() => setActionSuccess(false)}
      >
        <Alert onClose={() => setActionSuccess(false)} severity="success">
          Problem {currentAction} successfull.
        </Alert>
      </Snackbar>

      <Snackbar
        open={actionError}
        autoHideDuration={3000}
        onClose={() => setActionError(false)}
      >
        <Alert onClose={() => setActionError(false)} severity="error">
          Problem {currentAction} failed.
        </Alert>
      </Snackbar>
    </Card>
  );
};

ProblemTable.propTypes = {
  className: PropTypes.string,
  slug: PropTypes.string,
};

export default ProblemTable;
