import { combineReducers } from 'redux';

import userReducer from './userReducer';
import adminReducer from './adminReducer';
import merchantReducer from './merchantReducer.js';
import termsAndConditionsReudcers from './termsAndConditionsReducer';
import privacyPolicyReudcers from './privacyPolicyReducer';
import helpReudcers from './helpReducer';
import offerReducer from './offerReducer';
import problemReducer from './problemReducer';

const appReducer = combineReducers({
  user: userReducer,
  admin: adminReducer,
  merchant: merchantReducer,
  termsAndConditions: termsAndConditionsReudcers,
  privacyPolicy: privacyPolicyReudcers,
  help: helpReudcers,
  offer: offerReducer,
  problem: problemReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_USER') {
    state = undefined;
  }

  return appReducer(state, action);
};
export default rootReducer;
