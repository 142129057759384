import api from './api';
import { getMerchantTransactions } from 'src/store/actions/merchantActions';

const merchantService = {
  makePayment: async (data) => {
    try {
      let res = await api.post('/api/v1/transaction/add', data);
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPaymentClient: async (data) => {
    try {
      let res = await api.get(
        `/api/v1/user/clientProfile/${data.tagData}`,
        data,
      );
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getTransactions: async (dispatch) => {
    try {
      let res = await api.get('/api/v1/transaction/merchant');
      dispatch(getMerchantTransactions(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getDashboardTransactionHistory: async () => {
    try {
      let res = await api.get(
        '/api/v1/transaction/merchant/dashboardThirtyDayTransactions',
      );
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getRechargeAndPayments: async () => {
    try {
      let res = await api.get(
        '/api/v1/transaction/merchant/rechargeAndPayment',
      );
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  registerClient: async (data) => {
    try {
      const res = await api.post(`/api/v1/auth/register/client`, data, {
        headers: {
          'content-type': 'application/json',
        },
      });
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default merchantService;
