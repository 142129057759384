import React, { useState } from 'react';
import { Box, Snackbar } from '@material-ui/core';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'draft-js/dist/Draft.css';
import htmlToDraft from 'html-to-draftjs';
import { useEffect } from 'react';
import termsAndConditionServices from 'src/services/termsAndConditionService';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { Alert } from '@material-ui/lab';

const ContentBox = ({
  editorState,
  setEditorState,
  getDataFunc,
  resourceName,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    async function getTermsAndConditions() {
      try {
        const res = await getDataFunc(dispatch);
        if (res.data && res.ok && res.data?.[0]?.[resourceName]) {
          const contentBlock = htmlToDraft(res.data?.[0]?.[resourceName]);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
              contentBlock.contentBlocks,
            );
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
          }
        } else if (res.data && res.ok && !res.data?.[0]?.[resourceName]) {
          <Snackbar autoHideDuration={3000}>
            <Alert severity="error">No {resourceName} found</Alert>
          </Snackbar>;
        }
      } catch (error) {
        console.log(error);
      }
    }
    getTermsAndConditions();
  }, []);
  return (
    <>
      <Box
        style={{
          border: '1px solid #e0e0e0',
          height: '500px',
          maxHeight: '600px',
          overflow: 'auto',
          borderRadius: '5px',
          padding: '5px',
        }}
      >
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={setEditorState}
        />
      </Box>
    </>
  );
};

ContentBox.propTypes = {
  editorState: PropTypes.object,
  setEditorState: PropTypes.func,
  getDataFunc: PropTypes.func,
  resourceName: PropTypes.string,
};

export default ContentBox;
