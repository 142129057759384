import api from './api';

import {
  adminGetUsers,
  adminAddUser,
  adminUpdateUser,
  adminDeleteUser,
  adminGetTransaction,
} from '../store/actions/adminActions';

const adminService = {
  // USER
  getUsers: async (slug, dispatch) => {
    try {
      const res = await api.get(`/api/v1/auth/users/${slug}`);
      dispatch(adminGetUsers(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addUser: async (data, slug, dispatch) => {
    try {
      const res = await api.post(`/api/v1/auth/register/${slug}`, data, {
        headers: {
          'content-type': 'application/json',
        },
      });
      dispatch(adminAddUser(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateUser: async (_id, slug, data, dispatch) => {
    try {
      const res = await api.patch(`/api/v1/auth/update/${slug}/${_id}`, data, {
        headers: {
          'content-type': 'application/json',
        },
      });
      dispatch(adminUpdateUser(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteUser: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/auth/users/${_id}`);
      dispatch(adminDeleteUser(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getTransaction: async (dispatch) => {
    try {
      let res = await api.get('/api/v1/transaction/all');
      dispatch(adminGetTransaction(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getTranslation: async (text) => {
    try {
      let res = await api.post('/api/v1/admin/translate/chinese', {
        text: text,
      });
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default adminService;
