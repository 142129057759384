import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import {
  Box,
  Button,
  Dialog,
  makeStyles,
  Typography,
  capitalize,
  Snackbar,
} from '@material-ui/core';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import { useDispatch } from 'react-redux';
import { Alert } from '@material-ui/lab';

// import AddPromotion from './AddPromotion';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const Toolbar = ({
  className,
  title,
  addDataFunc,
  resourceName,
  editorState,
  ...rest
}) => {
  const classes = useStyles();
  const location = useLocation();
  const [addModal, setAddModal] = useState(false);

  const dispatch = useDispatch();
  const [dataAddSuccess, setDataAddSuccess] = useState(false);
  const [dataFetchError, setDataFetchError] = useState(false);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="space-between">
        {/* <Button className={classes.importButton}>Import</Button>
        <Button className={classes.exportButton}>Export</Button> */}
        <Typography variant="h2">{title}</Typography>
      </Box>
      <Snackbar
        open={dataAddSuccess}
        autoHideDuration={3000}
        onClose={() => setDataAddSuccess(false)}
      >
        <Alert onClose={() => setDataAddSuccess(false)} severity="success">
          {title} saved successfully.
        </Alert>
      </Snackbar>
      <Snackbar
        open={dataFetchError}
        onClose={() => setDataFetchError(false)}
        autoHideDuration={3000}
      >
        <Alert severity="error">Error adding data</Alert>
      </Snackbar>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  editorState: PropTypes.object,
  title: PropTypes.string,
  addDataFunc: PropTypes.func,
  resourceName: PropTypes.string,
};

export default Toolbar;
