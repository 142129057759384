import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';

import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';

import SettingsView from 'src/views/settings/SettingsView';
import { isLoggedIn } from './utils/helper';

import Error500 from './views/errors/Error500';
import TransactionView from './views/transaction/TransactionView';
import PaymentView from './views/payment/PaymentView';
import RechargeView from './views/recharge/RechargeView';

import ClientRegisterView from './views/register/ClientRegisterView';
import RegisterView from './views/auth/RegisterView';
import VerifyAccount from './views/auth/VerifyAccount';
import TermsAndConditionsView from './views/termsAndConditions/termsAndConditionsView';
import PrivacyPolicyView from './views/termsAndConditions/privacyPolicyView';
import HelpView from './views/termsAndConditions/helpView';
import OfferView from './views/offer/OfferView';
import ProblemView from './views/problem/ProblemView';
export const getRoutes = (pathname) => {
  const routes = [
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: 'register', element: <RegisterView /> },
        { path: 'verify', element: <VerifyAccount /> },
        {
          path: 'login',
          element: isLoggedIn() ? <Navigate to="/" /> : <LoginView />,
        },
        { path: '404', element: <NotFoundView /> },
        { path: '500', element: <Error500 /> },

        {
          path: '/',
          element: isLoggedIn() ? (
            <DashboardLayout />
          ) : (
            <Navigate to="/login" state={{ pathname }} />
          ),
          children: [
            { path: '/', element: <Navigate to="/dashboard" /> },
            { path: 'account', element: <AccountView /> },
            { path: 'payment', element: <PaymentView /> },
            { path: 'recharge', element: <RechargeView /> },
            { path: 'register', element: <ClientRegisterView /> },

            // { path: '/users/:slug', element: <UserListView /> },
            { path: 'dashboard', element: <DashboardView /> },
            { path: 'settings', element: <SettingsView /> },
            {
              path: 'offer',
              children: [
                {
                  path: '/',
                  element: <OfferView />,
                },
              ],
            },
            {
              path: 'policies',
              children: [
                {
                  path: '/terms-and-conditions',
                  element: <TermsAndConditionsView />,
                },
                {
                  path: '/privacy-policy',
                  element: <PrivacyPolicyView />,
                },
                {
                  path: '/help',
                  element: <HelpView />,
                },
              ],
            },
            {
              path: 'problem',
              children: [
                {
                  path: '/',
                  element: <ProblemView />,
                },
              ],
            },
            {
              path: 'transaction',
              children: [
                {
                  path: '/',
                  element: <TransactionView />,
                },
              ],
            },
            { path: '*', element: <NotFoundView /> },
          ],
        },
        { path: '*', element: <NotFoundView /> },
      ],
    },
    // {
    //   path: '/',
    //   element: isLoggedIn() ? <DashboardLayout /> : <Navigate to="/login" />,
    //   children: [
    //     { path: 'account', element: <AccountView /> },
    //     { path: 'customers', element: <UserListView /> },
    //     { path: 'dashboard', element: <DashboardView /> },
    //     { path: 'products', element: <ProductListView /> },
    //     { path: 'settings', element: <SettingsView /> },
    //     { path: '*', element: <Navigate to="/404" /> },
    //   ],
    // },
  ];
  return routes;
};

// export default routes;
