import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  makeStyles,
  useTheme,
} from '@material-ui/core';

import merchantService from 'src/services/merchantService';

import { BsCreditCard } from 'react-icons/bs';
import { AiOutlineDollarCircle } from 'react-icons/ai';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
}));

const RechargeAndPayment = ({ className, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [rechargeAndPayments, setRechargeAndPayments] = React.useState({});

  useEffect(() => {
    async function getRechargeAndPayments() {
      try {
        let res = await merchantService.getRechargeAndPayments();
        setRechargeAndPayments(res.data);
      } catch (error) {
        console.log(error);
      }
    }
    getRechargeAndPayments();
  }, []);

  // console.log('reachagr', rechargeAndPayments);

  const data = {
    datasets: [
      {
        data: [
          rechargeAndPayments.rechargeInfo?.length || 0,
          rechargeAndPayments.paymentInfo?.length || 0,
        ],
        backgroundColor: [colors.red[600], colors.orange[600]],
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white,
      },
    ],
    labels: ['Recharge', 'Payment'],
  };

  let rechargePercent = 0;

  if (
    rechargeAndPayments.rechargeInfo?.length > 0 ||
    rechargeAndPayments.paymentInfo?.length > 0
  ) {
    rechargePercent = Math.round(
      (rechargeAndPayments.rechargeInfo?.length /
        (rechargeAndPayments.rechargeInfo?.length +
          rechargeAndPayments.paymentInfo?.length)) *
        100,
    );
  }

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary,
    },
  };

  const info = [
    {
      title: 'Payment',
      value:
        rechargeAndPayments.paymentInfo?.length > 0 ? 100 - rechargePercent : 0,
      icon: BsCreditCard,
      color: colors.orange[600],
    },
    {
      title: 'Recharge',
      value: rechargePercent,
      icon: AiOutlineDollarCircle,
      color: colors.red[600],
    },
  ];

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Recharge And Payments" />
      <Divider />
      <CardContent>
        <Box height={300} position="relative">
          <Doughnut data={data} options={options} />
        </Box>
        <Box display="flex" justifyContent="center" mt={2}>
          {info.map(({ color, icon: Icon, title, value }) => (
            <Box key={title} p={1} textAlign="center">
              <Icon color="action" size={30} />
              <Typography color="textPrimary" variant="body1">
                {title}
              </Typography>
              <Typography style={{ color }} variant="h2">
                {value}
                {'%'}
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

RechargeAndPayment.propTypes = {
  className: PropTypes.string,
};

export default RechargeAndPayment;
