import { ADMIN_GET_HELP, ADMIN_ADD_HELP } from '../actions/helpActions';

const initialState = {
  help: [],
};

export default function helpReudcers(state = initialState, action) {
  switch (action.type) {
    case ADMIN_GET_HELP: {
      return {
        ...state,
        help: [...action.payload],
      };
    }
    case ADMIN_ADD_HELP: {
      const help = [];
      help.push(action.payload);
      return {
        ...state,
        help: help,
      };
    }
    default:
      return state;
  }
}
