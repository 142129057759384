import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import userService from 'src/services/userService';
import { green } from '@material-ui/core/colors';
import { getImageUrl } from 'src/utils/helper';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100,
  },
  danger: {
    color: 'red',
  },
  success: {
    color: 'green',
  },
  wrapper: {
    position: 'relative',
  },
  uploadProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
}));

const Profile = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const [uploadError, setUploadError] = useState('');
  const [file, setFile] = useState(null);
  const classes = useStyles();

  const inputRef = useRef(null);

  const [uploading, setUploading] = useState(false);

  const handleUploadClick = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };

  const handleFileSelect = (e) => {
    e.preventDefault();
    setUploadError('');
    const imgTypes = ['image/jpeg', 'image/png'];
    const selectedFile = e.target.files[0];
    if (selectedFile && imgTypes.includes(selectedFile.type)) {
      setFile(selectedFile);
    } else {
      setFile('');
      inputRef.current.value = '';
      setUploadError('Please select image with jpeg or png format.');
    }
  };

  const uploadAvatar = async (e) => {
    e.preventDefault();
    setUploading(true);
    const formData = new FormData();
    formData.append('avatar', file);
    try {
      await userService.changeAvatar(formData, dispatch);
      setFile(null);
    } catch (error) {
      console.error(error);
      setUploadError('Upload Error.');
    } finally {
      setUploading(false);
    }
  };

  const user = useSelector((state) => state.user.profile);

  console.log(getImageUrl(user.avatar));

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Box alignItems="center" display="flex" flexDirection="column">
          <div className={classes.wrapper}>
            <Avatar
              className={classes.avatar}
              src={file ? URL.createObjectURL(file) : getImageUrl(user.avatar)}
            />
            {uploading && (
              <CircularProgress
                size={112}
                thickness={1.5}
                className={classes.uploadProgress}
              />
            )}
          </div>

          <Typography color="textPrimary" gutterBottom variant="h3">
            {user.name}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            {`${user.email}`}
          </Typography>
          <Typography
            className={classes.dateText}
            color="textSecondary"
            variant="body1"
          >
            {`${moment(user.createdAt).format('YYYY/MM/DD hh:mm')}`}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <input
          type="file"
          id="upload-avatar"
          accept="image/jpeg, image/png"
          onChange={handleFileSelect}
          onClick={(e) => (e.target.value = null)}
          ref={inputRef}
          hidden
        />
        {file ? (
          <>
            <Button
              className={classes.success}
              fullWidth
              variant="text"
              onClick={uploadAvatar}
            >
              Upload Avatar
            </Button>
            <Button
              className={classes.danger}
              fullWidth
              variant="text"
              onClick={() => {
                setFile(null);
                inputRef.current.value = '';
              }}
            >
              Cancel Upload
            </Button>
          </>
        ) : (
          <Button
            color="primary"
            fullWidth
            variant="text"
            onClick={handleUploadClick}
          >
            Change Avatar
          </Button>
        )}
      </CardActions>
      {uploadError && (
        <Alert severity="error" onClose={() => setUploadError('')}>
          {uploadError}
        </Alert>
      )}
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
};

export default Profile;
