import api from './api';
import {
  adminGetPrivacyPolicy,
  adminAddPrivacyPolicy,
} from '../store/actions/privacyPolicyActions';

const privacyPolicyServices = {
  getPrivacyPolicy: async (dispatch) => {
    try {
      const res = await api.get(`/api/v1/privacy-policy`);
      dispatch(adminGetPrivacyPolicy(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default privacyPolicyServices;
