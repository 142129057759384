import {
  ADMIN_GET_PROBLEMS,
  ADMIN_ADD_PROBLEM,
  ADMIN_UPDATE_PROBLEM,
  ADMIN_DELETE_PROBLEM,
} from '../actions/problemActions';

const initialState = {
  problems: [],
};

export default function problemReducer(state = initialState, action) {
  switch (action.type) {
    case ADMIN_GET_PROBLEMS: {
      return {
        ...state,
        problems: [...action.payload],
      };
    }
    case ADMIN_ADD_PROBLEM: {
      const problems = [...state.problems];
      problems.push(action.payload);
      return {
        ...state,
        problems: problems,
      };
    }
    case ADMIN_UPDATE_PROBLEM: {
      const problems = [...state.problems];
      let index = problems.findIndex((item) => item._id === action.payload._id);
      problems[index] = action.payload;
      return {
        ...state,
        problems: problems,
      };
    }
    case ADMIN_DELETE_PROBLEM: {
      const problems = [...state.problems];
      let index = problems.findIndex((item) => item._id === action.payload._id);
      problems.splice(index, 1);
      return {
        ...state,
        problems: problems,
      };
    }
    default:
      return state;
  }
}
