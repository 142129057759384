import {
  Avatar,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  makeStyles,
  MenuItem,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Page from 'src/components/Page';

import { consoleError } from 'src/utils/helper';
import * as yup from 'yup';
import { TextField, Switch } from 'formik-material-ui';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useLocation } from 'react-router';
import merchantService from 'src/services/merchantService';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import encryptTag from 'src/utils/encryptTag';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // height: 'auto',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  switchField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function ClientRegisterView({ user = null }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const slug = location.pathname.replace('/users/', '');

  const [update, setUpdate] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [clientInfo, setClientInfo] = useState({});
  const [showTag, setShowTag] = useState(false);

  const handleClickShowTag = () => {
    setShowTag(!showTag);
  };

  const handleClickShowPassword = () => {
    showPassword ? setShowPassword(false) : setShowPassword(true);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (user) {
      setUpdate(true);
    }
  }, [user]);

  const registerClientSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    status: yup
      .string()
      .required('Status is required')
      .oneOf(['active', 'inactive']),
    password: yup
      .string()
      .min(8, 'Password is minimum 8 characters.')
      .required('Password is required.'),
    email: yup.string().email().required('Email is required'),

    phone: yup
      .string()
      .matches(
        /^[9][0-9]{9}$/,
        'Invalid phone number. Phone number must be in this format: 9876543210',
      )
      .required('Phone number is required'),
    tagData: yup
      .number()
      .test(
        'Is positive?',
        'ERROR: The number must be greater than 0!',
        (value) => value > 0,
      )
      .required('Tag is required'),
    amount: yup.number().required('Amount is required'),
  });

  const registerClient = async (data) => {
    try {
      data.tagData = encryptTag(data.tagData);
      data.phone = '+977' + data.phone;
      let res = await merchantService.registerClient(data);
      if (res && res.ok) {
        setSuccessMsg(res.message || 'User added successfully.');
      }
    } catch (error) {
      consoleError(error);
      setErrMsg(error.message || 'Error adding user. Please try again.');
    }
  };

  return (
    <Page className={classes.root} title={'Register Client'}>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-title">
            {'Do you want to confirm client registration?'}
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <strong>Client Name: </strong> {clientInfo.name}
              <br />
              <strong>Email: </strong> {clientInfo.email}
              <br />
              <strong>Phone: </strong> {clientInfo.phone}
              <br />
              <strong>Status: </strong> {clientInfo.status}
              <br />
              <strong>Amount: </strong> {clientInfo.amount}
              <br />
              <strong>Password: </strong> {clientInfo.password}
              <br />
              <strong>TagData: </strong> {clientInfo.tagData}
              <br />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                registerClient(clientInfo);
                handleClose();
              }}
              color="primary"
            >
              Yes
            </Button>
            <Button onClick={handleClose} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <h1
            style={{
              fontFamily: 'Roboto',
              textAlign: 'center',
              marginTop: '20px',
              marginBottom: '20px',
              color: '#3f51b5',
            }}
          >
            Client Registration
          </h1>
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: '',
              tagData: '',
              amount: '',
              phone: '',
              status: 'active',
              email: '',
            }}
            validationSchema={registerClientSchema}
            onSubmit={async (values, { resetForm }) => {
              setErrMsg('');
              setSuccessMsg('');
              const {
                name,
                tagData,
                amount,
                phone,
                password,
                email,
                status,
              } = values;
              const data = {
                name,
                tagData,
                amount,
                phone,
                password,
                email,
                status,
              };
              setClientInfo(data);
              handleOpen();
              // try {
              //   let res = await merchantService.registerClient(data);
              //   if (res && res.ok) {
              //     setSuccessMsg(res.message || 'User added successfully.');
              //     resetForm({});
              //     window.location.reload();
              //   }
              // } catch (error) {
              //   consoleError(error);
              //   setErrMsg(
              //     error.message || 'Error adding user. Please try again.',
              //   );
              // }
            }}
          >
            {({ isSubmitting, values }) => (
              <Form>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Name"
                      size="medium"
                      name="name"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Email"
                      size="medium"
                      name="email"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Phone"
                      size="medium"
                      name="phone"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl variant="outlined" fullWidth>
                      <Field
                        component={TextField}
                        name="status"
                        type="text"
                        label="Status"
                        select={true}
                        variant="outlined"
                        fullWidth
                      >
                        <MenuItem value={'active'}>Active</MenuItem>
                        <MenuItem value={'inactive'}>Inactive</MenuItem>
                      </Field>
                      {/* <ErrorMessage name="type" /> */}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Field
                      component={TextField}
                      label="amount"
                      size="medium"
                      name="amount"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Password"
                      size="medium"
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="TagData"
                      size="medium"
                      name="tagData"
                      fullWidth
                      variant="outlined"
                      type={showTag ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowTag}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showTag ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <Box mt={1} mb={1}>
                  {errMsg && (
                    <Alert severity="error" onClose={() => setErrMsg('')}>
                      <AlertTitle>Error</AlertTitle>
                      {errMsg}
                    </Alert>
                  )}
                  {successMsg && (
                    <Alert severity="success" onClose={() => setSuccessMsg('')}>
                      <AlertTitle>Success</AlertTitle>
                      {successMsg}
                    </Alert>
                  )}
                </Box>
                {isSubmitting && <LinearProgress />}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="medium"
                    type="submit"
                    variant="contained"
                  >
                    Add User
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
}

export default ClientRegisterView;
