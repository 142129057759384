export const ADMIN_GET_PROBLEMS = 'ADMIN_GET_PROBLEMS';
export const ADMIN_ADD_PROBLEM = 'ADMIN_ADD_PROBLEM';
export const ADMIN_UPDATE_PROBLEM = 'ADMIN_UPDATE_PROBLEM';
export const ADMIN_DELETE_PROBLEM = 'ADMIN_DELETE_PROBLEM';

export function adminGetProblems(payload) {
  return { type: ADMIN_GET_PROBLEMS, payload };
}
export function adminAddProblem(payload) {
  return { type: ADMIN_ADD_PROBLEM, payload };
}
export function adminUpdateProblem(payload) {
  return { type: ADMIN_UPDATE_PROBLEM, payload };
}
export function adminDeleteProblem(payload) {
  return { type: ADMIN_DELETE_PROBLEM, payload };
}
