import api from './api';
import {
  adminGetTermsAndConditions,
  adminAddTermsAndConditions,
} from '../store/actions/termsAndConditionsActions';

const termsAndConditionServices = {
  getTermsAndConditions: async (dispatch) => {
    try {
      const res = await api.get(`/api/v1/terms-and-conditions`);
      dispatch(adminGetTermsAndConditions(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default termsAndConditionServices;
