import api from './api';

import {
  getProfile,
  loginUser,
  logoutUser,
  updateProfile,
  changeAvatar,
} from '../store/actions/userActions';

const userService = {
  login: async (data, dispatch) => {
    try {
      const res = await api.post('/api/v1/auth/login', data);
      console.log(data);
      if (res.accessToken) {
        localStorage.setItem('accessToken', res.accessToken);
      }
      console.log('response', res);
      dispatch(loginUser(res.user));
      return Promise.resolve(res);
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  },

  merchantLogin: async (data, dispatch) => {
    try {
      const res = await api.post('/api/v1/auth/login', data);
      if (res?.user?.phoneVerified === false) {
        setTimeout(() => {
          // navigate('/verify');
          window.location = '/verify';
        }, 1000);
        return;
      }
      console.log(data);
      if (res.accessToken) {
        localStorage.setItem('accessToken', res.accessToken);
      }
      console.log('response', res);
      dispatch(loginUser(res.user));
      return Promise.resolve(res);
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  },

  logout: async (dispatch) => {
    try {
      localStorage.removeItem('accessToken');
      dispatch(logoutUser());
      window.location.href = '/login';
      return Promise.resolve();
    } catch (error) {
      localStorage.removeItem('accessToken');
      dispatch(logoutUser());
      console.error(error);
    }
  },
  profile: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/user/profile');
      if (res && res.ok) {
        dispatch(getProfile(res.data));
        return Promise.resolve(res);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateProfile: async (data, dispatch) => {
    try {
      const res = await api.patch('/api/v1/user/profile', data);
      if (res && res.ok) {
        dispatch(updateProfile(res.data));
        return Promise.resolve(res);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  changeAvatar: async (formData, dispatch) => {
    try {
      const res = await api.post('/api/v1/user/profile/avatar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (res && res.ok) {
        dispatch(changeAvatar(res.data));
        return Promise.resolve(res);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  changePassword: async (data) => {
    try {
      const res = await api.patch('/api/v1/user/profile/change-password', data);
      if (res && res.ok) {
        return Promise.resolve(res);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default userService;
