import { MERCHANT_TRANSACTIONS } from '../actions/merchantActions';

const initialState = {
  transactions: [],
};

export default function merchantReducer(state = initialState, action) {
  switch (action.type) {
    // USERS
    // TRANSACTION
    case MERCHANT_TRANSACTIONS:
      return {
        ...state,
        transactions: [...action.payload],
      };
    default:
      return state;
  }
}
