// USERS
export const ADMIN_GET_USERS = 'ADMIN_GET_USERS';
export const ADMIN_ADD_USER = 'ADMIN_ADD_USER';
export const ADMIN_UPDATE_USER = 'ADMIN_UPDATE_USER';
export const ADMIN_DELETE_USER = 'ADMIN_DELETE_USER';

export function adminGetUsers(payload) {
  return { type: ADMIN_GET_USERS, payload };
}
export function adminAddUser(payload) {
  return { type: ADMIN_ADD_USER, payload };
}
export function adminUpdateUser(payload) {
  return { type: ADMIN_UPDATE_USER, payload };
}
export function adminDeleteUser(payload) {
  return { type: ADMIN_DELETE_USER, payload };
}

// TRANSACTION
export const ADMIN_TRANSACTION_ALL = 'ADMIN_TRANSACTION_ALL';

export function adminGetTransaction(payload) {
  return { type: ADMIN_TRANSACTION_ALL, payload };
}
