import api from './api';
import {
  adminGetProblems,
  adminAddProblem,
  adminUpdateProblem,
  adminDeleteProblem,
} from '../store/actions/problemActions';

const ProblemService = {
  getProblems: async (dispatch) => {
    try {
      const res = await api.get(`/api/v1/ticket/me`);
      dispatch(adminGetProblems(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addProblem: async (Problem, dispatch) => {
    try {
      const res = await api.post(`/api/v1/ticket`, Problem);
      dispatch(adminAddProblem(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateProblem: async (id, Problem, dispatch) => {
    try {
      const res = await api.patch(`/api/v1/ticket/${id}`, Problem);
      dispatch(adminUpdateProblem(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteProblem: async (id, dispatch) => {
    try {
      console.log(id);
      const res = await api.delete(`/api/v1/ticket/${id}`);
      dispatch(adminDeleteProblem(res.data));
      return Promise.resolve(res);
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  },
  // triggerNotification: async (id) => {
  //   try {
  //     const res = await api.post(`/api/v1/Problem/notification/${id}`);
  //     return Promise.resolve(res);
  //   } catch (error) {
  //     console.log(error);
  //     return Promise.reject(error);
  //   }
  // },
};

export default ProblemService;
