import api from './api';
import {
  adminGetOffers,
  adminAddOffer,
  adminUpdateOffer,
  adminDeleteOffer,
} from '../store/actions/offerActions';

const offerServices = {
  getOffers: async (dispatch) => {
    try {
      const res = await api.get(`/api/v1/offer/active`);
      dispatch(adminGetOffers(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default offerServices;
