import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Toolbar from '../Toolbar';
import { EditorState } from 'draft-js';
import { useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'draft-js/dist/Draft.css';
import ContentBox from '../ContentBox';
import privacyPolicyServices from 'src/services/privacyPolicyService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  grid: {
    height: '100%',
  },
}));

const PrivacyPolicyView = () => {
  const classes = useStyles();
  const location = useLocation();

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );

  return (
    <Page className={classes.root} title="Privacy Policy">
      <Container maxWidth={false}>
        <Toolbar
          title="Privacy Policy"
          addDataFunc={privacyPolicyServices.addPrivacyPolicy}
          resourceName="privacyPolicy"
          editorState={editorState}
        />
        <Grid container spacing={3} direction="column">
          <Grid item lg={12} md={12} xl={12} xs={12}>
            {' '}
            <Box
              mt={3}
              style={{
                backgroundColor: 'white',
                padding: '10px',
                borderRadius: '5px',
              }}
            >
              <ContentBox
                editorState={editorState}
                setEditorState={setEditorState}
                getDataFunc={privacyPolicyServices.getPrivacyPolicy}
                resourceName="privacyPolicy"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default PrivacyPolicyView;
