import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  makeStyles,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import merchantService from 'src/services/merchantService';
import { consoleError } from 'src/utils/helper';
import * as yup from 'yup';
import { TextField } from 'formik-material-ui';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useLocation } from 'react-router';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import encryptTag from 'src/utils/encryptTag';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // height: 'auto',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  switchField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function RechargeView({ user = null }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const [errMsg, setErrMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [showTagData, setShowTagData] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [successInfo, setSuccessInfo] = useState({});

  const merchant = useSelector((state) => state.user.profile);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addPaymentSchema = yup.object().shape({
    amount: yup.number().required('Amount is required'),
    tagData: yup.number().required('Tag Data is required'),
  });

  const handleClickShowPassword = () => {
    showTagData ? setShowTagData(false) : setShowTagData(true);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [paymentData, setPaymentData] = useState({
    amount: '',
    client: '',
    tagData: '',
  });

  const submitTransaction = async (values) => {
    setErrMsg('');
    setSuccessMsg('');
    try {
      const res = await merchantService.makePayment({
        amount: +values.amount,
        tagData: encryptTag(values.tagData),
        rechargeRequest: true,
        channel: 'web',
      });

      console.log(res);
      if (res && res.ok) {
        setSuccessMsg(res.message);
        setPaymentData({
          amount: '',
          client: '',
          tagData: '',
        });
      }
    } catch (error) {
      consoleError(error);
      setErrMsg(error.message);
    }
    handleClose();
  };

  return (
    <Page className={classes.root} title={'Payment'}>
      {/* <div>
        <button type="button" onClick={handleOpen}>
          react-transition-group
        </button>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <h2 id="transition-modal-title">Transition modal</h2>
              <p id="transition-modal-description">
                react-transition-group animates me.
              </p>
            </div>
          </Fade>
        </Modal>
      </div> */}
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-title">
            {'Do you want to proceed with the recharge?'}
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <strong>Amount: Rs.</strong> {paymentData.amount}
              <br />
              <strong>Merchant:</strong> {merchant.name}
              <br />
              <strong>Client:</strong> {paymentData.client}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                submitTransaction(paymentData);
              }}
              color="primary"
            >
              Yes
            </Button>
            <Button onClick={handleClose} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <h1
          style={{
            fontFamily: 'Roboto',
            textAlign: 'center',
            marginTop: '20px',
            color: '#3f51b5',
          }}
        >
          Recharge
        </h1>

        <Container maxWidth="sm">
          <Formik
            enableReinitialize={true}
            initialValues={{
              amount: paymentData.amount,
              tagData: paymentData.tagData,
            }}
            validationSchema={addPaymentSchema}
            onSubmit={async (values, { resetForm }) => {
              setErrMsg('');
              setSuccessMsg('');

              try {
                console.log(values);
                const res = await merchantService.getPaymentClient({
                  tagData: encryptTag(values.tagData),
                });
                if (res && res.ok) {
                  setSuccessMsg(res.message);
                  setSuccessInfo({
                    amount: values.amount,
                    client: res.data.name,
                    name: merchant.name,
                  });

                  setPaymentData({
                    amount: values.amount,
                    client: res.data.name,
                    tagData: values.tagData,
                  });

                  handleOpen();
                }
              } catch (error) {
                consoleError(error);
                setErrMsg(error.message);
              }
            }}
          >
            {({ isSubmitting, values }) => (
              <Form>
                {/* <Typography>Payment</Typograph> */}
                <Box mt={1} mb={1}>
                  {errMsg && (
                    <Alert severity="error" onClose={() => setErrMsg('')}>
                      <AlertTitle>Error</AlertTitle>
                      {errMsg}
                    </Alert>
                  )}
                  {successMsg && (
                    <Alert severity="success" onClose={() => setSuccessMsg('')}>
                      <AlertTitle>Success</AlertTitle>
                      <br />
                      <strong>Recharge Amount: Rs.</strong> {successInfo.amount}
                      <br />
                      <strong>Merchant:</strong> {successInfo.name}
                      <br />
                      <strong>Client:</strong> {successInfo.client}
                      <br />
                      <strong>Date:</strong>{' '}
                      {moment(new Date()).format('DD-MM-YYYY')}
                    </Alert>
                  )}
                </Box>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Payment Amount"
                      margin="normal"
                      size="medium"
                      name="amount"
                      type="number"
                      fullWidth
                      variant="outlined"
                      autoComplete="new-password"
                    />
                    <Field
                      component={TextField}
                      label="Tag Data"
                      margin="normal"
                      size="medium"
                      name="tagData"
                      type={showTagData ? 'text' : 'password'}
                      autoComplete="new-password"
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showTagData ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>

                {isSubmitting && <LinearProgress />}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="medium"
                    type="submit"
                    variant="contained"
                  >
                    RECHARGE ACCOUNT
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Container>

        {/* <Dialog open={deleteModal} onClose={closeDeleteModal}>
        
        </Dialog> */}
        {/* {confirmModal && (
          <>
            <DialogTitle onClose={closeConfirmModal}>Delete User</DialogTitle>
            <DialogContent>
              <Box
                height="50px"
                display="flex"
                alignItems="center"
                flexDirection="column"
              >
                <Typography variant="h6">
                  Do you really want to delete user?
                </Typography>
                <Typography variant="h6">
                  &quot;
                  <small></small>
                  &quot;
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeConfirmModal} color="primary">
                Cancel
              </Button>
              <Button onClick={null} color="primary">
                Yes
              </Button>
            </DialogActions>
          </>
        )} */}
      </Box>
    </Page>
  );
}

export default RechargeView;
