import api from './api';
import { adminGetHelp, adminAddHelp } from '../store/actions/helpActions';

const helpServices = {
  getHelp: async (dispatch) => {
    try {
      const res = await api.get(`/api/v1/help`);
      dispatch(adminGetHelp(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default helpServices;
