var hash = require('hash.js');

function encryptTag(tag) {
  const tagData = +tag;
  let hexString = tagData.toString(16);
  for (let i = hexString.length; i < 8; i++) {
    hexString = '0' + hexString;
  }
  const groupOfTwo = hexString.match(/.{1,2}/g).reverse();
  const hexToDecimalArr = [];
  groupOfTwo.forEach((group) => {
    hexToDecimalArr.push(parseInt(group, 16));
  });
  const join = hexToDecimalArr.reverse().join('');
  const final = '000' + join;
  const hashedTag = hash.sha256().update(final).digest('hex');
  return hashedTag;
}

export default encryptTag;
